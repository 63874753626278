import request from '../utils/request';
import {Guid} from 'guid-typescript';

export default class DataApi {
  public async GetDataList(systemId: string, searchDate: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: '/api/monitor-data/list',
        method: 'get',
        params: {
          systemId,
          searchDate
        }
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    });
  }

  public async SetCompare(systemId: string, unitId: number, compareValue?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: '/api/monitor-data/set',
        method: 'post',
        data: {
          systemId,
          unitId,
          compareValue
        }
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    });
  }

  public async GetHistorical(unitId: number, dt: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: '/api/monitor-data/historical',
        method: 'get',
        params: {
          unitId,
          dt
        }
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    });
  }
}
