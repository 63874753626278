





















































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import DataApi from '@/api/DataApi';

@Component({})
export default class CompareDataDetail extends Vue {
  @Prop({required: true}) private title!: string;
  @Prop({required: true, default: () => false}) private visible!: boolean;
  @Prop({required: true}) private unitId!: number;
  @Prop({}) private lastDate!: string;

  private api = new DataApi();

  private tableData = [];
  private listQuery = {
    date: ''
  };
  private tempUnitId: number | undefined;
  private tempDate: string = '';

  @Watch('unitId', {immediate: true})
  public getUnitId(v: number) {
    if (v != 0) {
      this.tempUnitId = v;
      this.list();
    }
  }

  @Watch('lastDate', {immediate: true})
  public getLastDate(v: string) {
    if (v) {
      this.tempDate = v;
    }
  }

  private mounted() {
    const now = new Date();
    this.listQuery.date = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
  }

  private async list() {
    if (!this.listQuery.date) {
      this.$message({
        type: 'warning',
        message: '请选择查询时间'
      });
      return false;
    }
    if (this.tempUnitId && this.listQuery.date) {
      const result = await this.api.GetHistorical(this.tempUnitId, this.listQuery.date);
      this.tableData = result;
      // const dateNow = new Date();
      // if (result && this.tempDate) {
      //   const td = new Date(this.tempDate);
      //   if (td < dateNow) {
      //     const tempTableData = result.filter((item: any) => {
      //       return new Date(item.createTime) <= new Date(this.tempDate);
      //     });
      //     this.tableData = tempTableData;
      //   }
      //
      // }
    }
  }

  private getFixed(v?: number) {
    if (v === 0) {
      return '0.000';
    }

    if (v) {
      return v.toFixed(3);
    }
  }

  private handlerBeforeClose() {
    this.$emit('beforeClose');
  }
}
