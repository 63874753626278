


























































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import DataApi from '@/api/DataApi';
import CompareDataDetail from './components/compare-data-details.vue';
import abp from '@/utils/abp';

@Component({
  components: {
    CompareDataDetail
  }
})
export default class CompareList extends Vue {
  private api = new DataApi();
  private tableData = [];
  private sysId = '';
  private loading = false;
  private lastDate = '';
  private compareValue: number | undefined;
  private compareTodayValue: number | undefined;
  private unitId: number = 0;
  private radioCompare = '';
  private cookie_name = 'COMPARE_BZBJL_FLOW_MODEL_';
  private search_date = '';
  private time_interval = 10;
  private timer1: number | undefined;
  private timer2: number | undefined;
  private isPlay = false;
  private disable = false;

  private title = '';
  private visible = false;

  @Watch('$route.params.cid', {immediate: true})
  public async getSystemId(v: any) {
    if (v) {
      this.sysId = v;
      await this.refresh();
    }
  }

  private async mounted() {
    this.timer1 = setInterval(this.list, 60000);
    if (!this.sysId) {
      this.sysId = '9bdd202c-7680-aa36-05f7-39feccb4e33a';
      await this.refresh();
    }
  }

  private getFixed(v?: number) {
    if (v === 0) {
      return '0.000';
    }

    if (v) {
      return v.toFixed(3);
    }
  }

  private async refresh() {
    this.tableData = [];
    this.isPlay = false;
    this.disable = false;
    this.time_interval = 10;
    this.search_date = '';
    if (this.timer2) {
      clearInterval(this.timer2);
    }
    await this.list();
  }

  private async list() {
    this.loading = true;
    // console.log(this.search_date);
    const result = await this.api.GetDataList(this.sysId, this.search_date);
    this.getCompareValue(result);
    this.tableData = result;
    this.loading = false;
  }

  private async handlerCellClick(row: any, column: any) {
    if (column.property === 'isCompare') {
      if (this.sysId) { // 存入本地Cookie
        this.setCompareValue(row);
        // await this.api.SetCompare(this.sysId, row.id, row.flow);
        await this.list();
      }
    }
  }

  private setCompareValue(row: any) {
    const model = {
      sysId: this.sysId,
      unitId: row.id
    };

    // 设置Cookie过期时间为一年
    const date = new Date();
    const dateYear = date.setFullYear(date.getFullYear() + 1);
    const newDate = new Date(dateYear);
    abp.utils.setCookieValue(this.cookie_name + this.sysId, JSON.stringify(model), newDate, '/');
  }

  private handlerDetails(row: any) {
    this.title = row.name;
    this.visible = true;
    this.unitId = row.id;
    this.lastDate = row.createTime;
  }

  private handlerClose() {
    this.visible = false;
  }

  private getCompareValue(v: any) {
    if (this.sysId) {
      this.radioCompare = '';
      this.compareValue = undefined;
      this.compareTodayValue = undefined;
      const model = abp.utils.getCookieValue(this.cookie_name + this.sysId);
      if (model) {
        const dateJson = JSON.parse(model);
        this.radioCompare = `${dateJson.sysId}-${dateJson.unitId}`;
        const result = v.find((vl: any) => vl.id === dateJson.unitId);
        if (!result) {
          this.setDefaultCompareValue(v);
        } else {
          this.compareValue = result.flow;
          this.compareTodayValue = result.day;
        }
      } else {
        this.setDefaultCompareValue(v);
      }
    }
  }

  private setDefaultCompareValue(v: any) {
    if (v.length > 0) {
      this.setCompareValue(v[0]);
    }
  }

  // 流量相对误差
  private getPercentage(flow?: number) {
    let result;
    if (this.compareValue && flow) {
      const jz = (flow - this.compareValue) / (this.compareValue / 100);
      result = jz.toFixed(2) + '%';
    }
    return result;
  }

  // 当日累计相对误差
  private getPercentageDay(day?: number) {
    let result;
    if (this.compareTodayValue && day) {
      const jz = (day - this.compareTodayValue) / (this.compareTodayValue / 100);
      result = jz.toFixed(2) + '%';
    }
    return result;
  }

  private play() {
    if (this.search_date && this.time_interval) {
      // 清除默认刷新时间
      if (this.timer1) {
        clearInterval(this.timer1);
      }
      if (this.timer2) {
        clearInterval(this.timer2);
      }

      this.disable = true;
      this.isPlay = true;
      const date = new Date(this.search_date);
      this.timer2 = setInterval(() => {
        const new_date = new Date(date.setMinutes(date.getMinutes() + 15));
        this.search_date = `${new_date.getFullYear()}-${new_date.getMonth() + 1}-${new_date.getDate()} ${new_date.getHours()}:${new_date.getMinutes()}`;
        this.list();
      }, this.time_interval * 1000);
    }
  }

  private pause() {
    if (this.timer2) {
      clearInterval(this.timer2);
    }
    this.disable = false;
  }

  private difference(beginTime: any) {
    let result = '';
    if (beginTime) {
      const dateBegin = new Date(beginTime);
      const now = new Date();

      const dateDiff = now.getTime() - dateBegin.getTime();
      const dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));
      const leave1 = dateDiff % (24 * 3600 * 1000);
      const hours = Math.floor(leave1 / (3600 * 1000));
      const leave2 = leave1 % (3600 * 1000);
      const minutes = Math.floor(leave2 / (60 * 1000));
      const leave3 = leave2 % (60 * 1000);
      const seconds = Math.round(leave3 / 1000);

      if (dayDiff > 0) {
        result = dayDiff + '天';
      } else if (hours > 0) {
        result += hours + '小时';
      } else if (minutes > 0) {
        result += minutes + '分';
      } else if (seconds > 0) {
        result += seconds + '秒';
      }
    }
    return result;
  }

  private change_date() {
    if (this.timer1) {
      clearInterval(this.timer1);
    }
    if (this.timer2) {
      clearInterval(this.timer2);
    }
    this.isPlay = true;
    this.list();
  }
}
